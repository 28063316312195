import * as THREE from 'three';
import Model from './Abstracts/Model.js';
import Experience from '../Experience.js';
import Debug from '../Utils/Debug.js';
import State from '../State.js';
import Materials from '../Materials/Materials.js';
import FBO from '@experience/Utils/FBO.js';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

import cubeBackgroundMaterialVertex from '@experience/Shaders/CubeBackgroundMaterial/vertex.glsl';
import cubeBackgroundMaterialFragment from '@experience/Shaders/CubeBackgroundMaterial/fragment.glsl';
import gsap from 'gsap';
import { MathUtils } from 'three';
import * as MathHelper from '@experience/Utils/MathHelper.js';

export default class Cube extends Model {
	experience = Experience.getInstance();
	debug = Debug.getInstance();
	state = State.getInstance();
	materials = Materials.getInstance();
	fbo = FBO.getInstance();
	sizes = experience.sizes;
	scene = experience.scene;
	time = experience.time;
	timeline = experience.timeline;
	camera = experience.camera.instance;
	renderer = experience.renderer.instance;
	resources = experience.resources;
	container = new THREE.Group();

	animationProgress = 0;

	scrollTarget = 0;
	scrollCurrent = 0;

	textTexture = new THREE.CanvasTexture( document.getElementById( 'screenshot-canvas' ) );
	gridTexture = null;

	constructor() {
		super();

		this.setModel();
		this.setDebug();
		this.setEvents();

		this.experience.world.on( 'ready', () => {
			this.sceneDepth = this.experience.world.sceneDepth;
			this.initDepthScene();
		} );

		this.textTexture.colorSpace = THREE.SRGBColorSpace;
		this.textTexture.needsUpdate = true;

		window.addEventListener( '3d-app:canvas-text', ( e ) => {
			this.textTexture = new THREE.CanvasTexture( e.detail.canvas );
			this.textTexture.colorSpace = THREE.SRGBColorSpace;
			this.textTexture.needsUpdate = true;
			this.planeFitPerspectiveCamera( this.plane, this.camera );
		} );


		window.addEventListener( '3d-app:canvas-grid', ( e ) => {
			this.gridTexture = new THREE.CanvasTexture( e.detail.canvas );
			this.gridTexture.colorSpace = THREE.SRGBColorSpace;
			this.gridTexture.needsUpdate = true;
			this.planeFitPerspectiveCamera( this.plane, this.camera );
		} );
	}

	setModel() {
		this.model = this.resources.items.cubeModel.scene;
		this.displacementTexture = this.resources.items.displacementTexture;
		this.hdrTexture = this.resources.items.hdrTexture;
		this.hdrTexture.wrapS = THREE.RepeatWrapping;
		this.hdrTexture.wrapT = THREE.RepeatWrapping;
		this.hdrTexture.colorSpace = THREE.SRGBColorSpace;
		this.hdrTexture.needsUpdate = true;

		const environment = new RoomEnvironment( this.renderer );
		const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
		const env = pmremGenerator.fromScene( environment ).texture;
		this.cubeMaterials = [];

		this.model.traverse( ( child ) => {
			if ( child.isMesh ) {
				child.scale.set( 0.0, 0.0, 0.0 );

				child.material.dispersion = 0.1;
				child.material.envMap = this.hdrTexture;
				child.material.roughness = 0.4;

				// add cubeMaterial
				this.cubeMaterials.push( child.material );

				child.material.onBeforeCompile = ( shader ) => {

					child.material.uniforms = shader.uniforms;
					child.material.uniforms.u_Time = new THREE.Uniform( 0 );
					child.material.uniforms.u_Cursor = new THREE.Uniform( new THREE.Vector2( 0, 0 ) );

					shader.vertexShader = `
						uniform float u_Time;
						uniform vec3 u_Cursor;
						
						mat2 get2dRotateMatrix(float _angle)
            {
                return mat2(cos(_angle), - sin(_angle), sin(_angle), cos(_angle));
            }
						${ shader.vertexShader }
					`;

					shader.vertexShader = shader.vertexShader.replace(
						'#include <begin_vertex>',
						`
							#include <begin_vertex>
							
							vec3 cursor = u_Cursor;
							//cursor.z += 0.1;
							
							vec4 pos = modelMatrix * vec4(transformed, 1.0);
							
							float distance = length(cursor - pos.xyz);
							
							distance = smoothstep(0.0, 0.3, distance);

							transformed.xyz *= 1.0 * distance;
						`,
					);
				};
			}
		} );

		// filter unique
		this.cubeMaterials = this.cubeMaterials.filter( ( v, i, a ) => a.findIndex( t => ( t.uuid === v.uuid ) ) === i );

		// add plane
		this.plane = new THREE.Mesh(
			new THREE.PlaneGeometry( 1, 1, 1, 1 ),
			new THREE.ShaderMaterial( {
				vertexShader: cubeBackgroundMaterialVertex,
				fragmentShader: cubeBackgroundMaterialFragment,
				uniforms: {
					u_Time: new THREE.Uniform( 0 ),
					u_Texture: { value: this.hdrTexture },
					u_TextTexture: { value: this.textTexture },
					u_TextTextureWH: new THREE.Uniform( new THREE.Vector2( 1, 1 ) ),
					u_GridTexture: { value: this.gridTexture },
					u_GridTextureWH: new THREE.Uniform( new THREE.Vector2( 1, 1 ) ),
					u_DepthTexture: { value: null },
					u_Scroll: new THREE.Uniform( 0 ),
					u_ScrollTranslate: new THREE.Uniform( 0 ),
					cameraNear: { value: this.camera.near },
					cameraFar: { value: this.camera.far },
					u_Resolution: { value: new THREE.Vector2( this.sizes.width * this.sizes.pixelRatio, this.sizes.height * this.sizes.pixelRatio ) },
					u_RepeatMap: { value: new THREE.Vector2( 1, 1 ) },
					u_RepeatMapText: { value: new THREE.Vector2( 1, 1 ) },
					u_RepeatMapGrid: { value: new THREE.Vector2( 1, 1 ) },
					u_DPR: new THREE.Uniform( this.sizes.pixelRatio ),
					u_TransitionProgress: new THREE.Uniform( -0.2 ),

					u_TextTextureProgress: new THREE.Uniform( 0 ),
				},
			} ),
		);
		this.plane.position.set( 0, 0, -2.4 );

		this.planeFitPerspectiveCamera( this.plane, this.camera );


		this.container.add( this.plane );
		this.container.add( this.model );

		this.scene.add( this.container );
	}

	initDepthScene() {
		this.planeDepth = this.plane.clone();
		this.planeDepth.material = new THREE.MeshBasicMaterial();

		this.modelDepth = this.model.clone();
		this.modelDepth.material = new THREE.MeshBasicMaterial();

		//this.sceneDepth.container.add( this.planeDepth );
		this.sceneDepth.container.add( this.modelDepth );
	}

	planeFitPerspectiveCamera( plane, camera ) {
		// Calculate the visible height and width of the mesh through the camera at a certain distance
		const vFOV = camera.fov * Math.PI / 180;

		// Calculate the distance between the camera and the plane
		const distance = camera.position.distanceTo( plane.position );
		const visibleHeight = 2 * Math.tan( vFOV / 2 ) * distance;

		// Calculate the visible width
		const aspect = camera.aspect;
		const visibleWidth = visibleHeight * aspect;

		// Update the plane's geometry
		plane.geometry.dispose();
		plane.geometry = new THREE.PlaneGeometry( visibleWidth, visibleHeight );


		// Set the repeat and offset properties of the background texture
		// to keep the image's aspect correct.
		const planeAspect = this.planeAspect = visibleWidth / visibleHeight;

		const imageAspect = this.hdrTexture.image.width / this.hdrTexture.image.height;
		const aspectImage = imageAspect / planeAspect;


		//
		// this.hdrTexture.offset.x = aspectImage > 1 ? (1 - 1 / aspectImage) / 2 : 0;
		//this.hdrTexture.repeat.x = aspectImage > 1 ? 1 / aspectImage : 1;
		this.plane.material.uniforms.u_RepeatMap.value.x = aspectImage > 1 ? 1 / aspectImage : 1;
		this.plane.material.uniforms.u_RepeatMap.value.y = aspectImage > 1 ? 1 : aspectImage;

		if ( this.textTexture ) {
			const imageAspectText = this.textTexture.image.width / this.textTexture.image.height;
			const aspectImageText = imageAspectText / this.planeAspect;


			this.plane.material.uniforms.u_RepeatMapText.value.x = aspectImageText > 1 ? 1 / aspectImageText : 1;
			this.plane.material.uniforms.u_RepeatMapText.value.y = aspectImageText > 1 ? 1 : aspectImageText;

			this.plane.material.uniforms.u_TextTextureWH.value.x = this.textTexture.image.width;
			this.plane.material.uniforms.u_TextTextureWH.value.y = this.textTexture.image.height;

			this.plane.material.uniforms.u_TextTexture.value = this.textTexture;

			this.textTexture.needsUpdate = true;
		}

		if ( this.gridTexture ) {
			const imageAspectGrid = this.gridTexture.image.width / this.gridTexture.image.height;
			const aspectImageGrid = imageAspectGrid / this.planeAspect;

			this.plane.material.uniforms.u_RepeatMapGrid.value.x = aspectImageGrid > 1 ? 1 / aspectImageGrid : 1;
			this.plane.material.uniforms.u_RepeatMapGrid.value.y = aspectImageGrid > 1 ? 1 : aspectImageGrid;

			this.plane.material.uniforms.u_GridTextureWH.value.x = this.textTexture.image.width;
			this.plane.material.uniforms.u_GridTextureWH.value.y = this.textTexture.image.height;

			this.plane.material.uniforms.u_GridTexture.value = this.gridTexture;

			//console.log(this.plane.material.uniforms.u_RepeatMapGrid.value.x, this.plane.material.uniforms.u_RepeatMapGrid.value.y)
		}
	}


	animation() {
		this.timeline.add(
			gsap.to( this, {
				duration: 2,
				animationProgress: 1.0,
				ease: 'bounce.out',
				onUpdate: () => {
					const progress = this.animationProgress;
					const progressDepth = MathUtils.clamp( progress - 0.1, 0, 1 );

					this.model.traverse( ( child ) => {
						if ( child.isMesh ) {
							child.scale.setScalar( progress );
						}

					} );

					this.modelDepth.traverse( ( child ) => {
						if ( child.isMesh ) {
							child.scale.setScalar( progressDepth );
						}
					} );
				},
			} ),
			'start',
		);

		this.timeline.add(
			gsap.to( this, {
				duration: 2,
				delay: 2,
				animationProgress: 0.0,
				ease: 'power2.out',
				onStart: () => {
					this.experience.trigger( 'cube:start-animation-out' );
				},
				onUpdate: ( tt ) => {
					const progress = this.animationProgress;
					const progressDepth = MathUtils.clamp( progress - 0.1, 0, 1 );
					this.plane.material.uniforms.u_TextTextureProgress.value = MathHelper.remap( progress, 1, 0, 0, 1 );


					this.model.traverse( ( child ) => {
						if ( child.isMesh ) {
							child.scale.setScalar( progress );
						}

					} );

					this.modelDepth.traverse( ( child ) => {
						if ( child.isMesh ) {
							child.scale.setScalar( progressDepth );
						}
					} );
				},
			} ),
			'start',
		);


	}


	resize() {
		this.plane.material.uniforms.u_Resolution.value.x = this.sizes.width * this.sizes.pixelRatio;
		this.plane.material.uniforms.u_Resolution.value.y = this.sizes.height * this.sizes.pixelRatio;
		this.plane.material.uniforms.u_DPR.value = this.sizes.pixelRatio;
		//
		// this.gridTexture.image.width = this.sizes.width * this.sizes.pixelRatio
		// this.gridTexture.image.height = this.sizes.height * this.sizes.pixelRatio

		this.planeFitPerspectiveCamera( this.plane, this.camera );
	}

	setDebug() {
		if ( !this.debug.active ) return;

		// if ( this.debug.panel ) {
		//     this.debugFolder = this.debug.panel.addFolder("First Material");
		// }
	}

	update( deltaTime ) {
		this.plane.material.uniforms.u_Time.value = this.time.elapsed;

		this.model.rotation.z += deltaTime * 0.3;
		this.modelDepth.rotation.z += deltaTime * 0.3;

		if ( this.sceneDepth ) {
			//this.planeDepth1.map = this.sceneDepth.depthRenderTarget.depthTexture;
			this.plane.material.uniforms.u_DepthTexture.value = this.sceneDepth.depthRenderTarget.depthTexture;
		}

		this.scrollCurrent = MathUtils.damp( this.scrollCurrent, this.scrollTarget, 9, this.time.delta );
		this.plane.material.uniforms.u_Scroll.value = this.scrollCurrent;

		this.cubeMaterials.forEach( ( material ) => {
			if ( material.uniforms?.u_Time ) {
				material.uniforms.u_Time.value = this.time.elapsed;
				material.uniforms.u_Cursor.value = this.experience.input.cursor3D_Smooth;
			}
		} );
	}

	setEvents() {
		window.addEventListener( 'app:normalized-scroll-y', ( event ) => {
			this.scrollTarget = event.detail.progress;
			this.plane.material.uniforms.u_ScrollTranslate.value = event.detail.translateY;
		} );
	}

}
