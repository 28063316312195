<template>
	<section :id="currentSection.id" class="home-projects">
		<div class="home-projects__container container">
			<swiper-container
				ref="swiperRef"
				slides-per-view="auto"
				:navigation="{
					prevEl: '.home-projects__navigation-button--prev',
					nextEl: '.home-projects__navigation-button--next',
				}"
				space-between="11"
				speed="500"
				:breakpoints="{
					768: { slidesPerView: '2' },
					992: { slidesPerView: '3' },
				}"
			>
				<swiper-slide v-for="project in projects" :key="project.slug">
					<div class="home-projects__card project-card">
						<div class="project-card__top">
							<div class="project-card__image">
								<img :src="project.media.image" alt="" class="cover-image" />
							</div>

							<div class="project-card__content">
								<h3 class="project-card__name font-heading m-0 uppercase">
									{{ project.title }}
								</h3>

								<div class="project-card__links">
									<a
										v-for="link in project.links"
										:key="link"
										class="project-card__link"
										:href="link.url"
										target="_blank"
										:aria-label="link.name"
									>
										<img :src="link.icon" :alt="link.name" class="contain-image" />
									</a>

									<button
										v-if="project?.media?.video?.url && project.media.video.type === 'youtube'"
										class="project-card__button-play"
										@click="openYoutubeVideo(project.media.video.url)"
										aria-label="Open Video Popup"
									>
										<IconProjectPlay />
									</button>

									<button
										v-else-if="project?.media?.video?.url && project.media.video.type === 'local'"
										class="project-card__button-play"
										@click="openLocalVideo(project.media.video.url, project.media.video.poster)"
										aria-label="Open Video Popup"
									>
										<IconProjectPlay />
									</button>
								</div>
							</div>
						</div>

						<div class="project-card__main-info">
							<div v-for="(item, index) in project.info" :key="index" class="project-card__info">
								<h4 v-if="item.title" class="project-card__info-name font-heading uppercase m-0">{{ item.title }}:</h4>

								<p class="project-card__info-description m-0">
									{{ item.text }}
								</p>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper-container>

			<div class="home-projects__slider-navigation">
				<button
					type="button"
					class="home-projects__navigation-button home-projects__navigation-button--prev"
					aria-label="Slider Button Prev"
				>
					<IconArrowSlider />
				</button>

				<button
					type="button"
					class="home-projects__navigation-button home-projects__navigation-button--next"
					aria-label="Slider Button Next"
				>
					<IconArrowSlider />
				</button>
			</div>
		</div>

		<YoutubeVideoModal v-if="hasYoutubeVideo" :videoId="currentVideoId" modal-id="youtube" />
		<LocalVideoModal
			v-if="hasLocalVideo"
			:videoURl="currentLocalVideoURL"
			:posterURL="currentLocalVideoPosterURL"
			modal-id="local"
		/>
	</section>
</template>

<script>
import 'vue-final-modal/style.css';
import projects from '@/data/projects.json';
import IconProjectPlay from '@/components/icons/IconProjectPlay.vue';
import IconArrowSlider from '@/components/icons/IconArrowSlider.vue';
import YoutubeVideoModal from '@/components/modals/YoutubeVideoModal.vue';
import LocalVideoModal from '@/components/modals/LocalVideoModal.vue';

export default {
	name: 'HomeProjects',
	components: { IconProjectPlay, IconArrowSlider, YoutubeVideoModal, LocalVideoModal },
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			projects: projects,
			hasYoutubeVideo: false,
			isYoutubeModalVisible: false,
			currentVideoId: '',
			hasLocalVideo: false,
			isLocalModalVisible: false,
			currentLocalVideoURL: '',
			currentLocalVideoPosterURL: '',
		};
	},
	mounted() {
		const youtubeProject = this.projects.find(
			(project) => project.media.video && project.media.video.type === 'youtube',
		);

		this.hasYoutubeVideo = !!youtubeProject;
		this.currentVideoId = this.extractVideoId(youtubeProject.media.video.url);

		this.hasLocalVideo = this.projects.some((project) => project.media.video && project.media.video.type === 'local');
	},
	methods: {
		openYoutubeVideo(youtubeURL) {
			const videoId = this.extractVideoId(youtubeURL);
			if (videoId) {
				this.currentVideoId = videoId;
				this.isModalVisible = true;
				this.$vfm.open('youtube');
			} else {
				console.error('Invalid YouTube URL');
			}
		},
		openLocalVideo(videoURL, posterURL) {
			this.currentLocalVideoURL = videoURL;
			this.currentLocalVideoPosterURL = posterURL;
			this.isLocalModalVisible = true;
			this.$vfm.open('local');
		},
		extractVideoId(url) {
			const regex =
				/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
			const match = url.match(regex);
			return match ? match[1] : null;
		},
	},
};
</script>
