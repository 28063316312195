<template>
	<header class="header">
		<div ref="headerWrap" class="header__wrapper">
			<div class="header__content">
				<div class="header__container container">
					<a ref="logo" href="/" class="header__logo">
						<img src="@/assets/images/logo.png" alt="Saguni Logo" />
					</a>

					<div ref="nav" class="header__nav-wrapper">
						<div ref="navInner" class="header__nav-inner">
							<nav class="header__nav">
								<ul class="header__nav-list list-unstyled">
									<li v-for="(item, index) in menu" :key="index" class="header__nav-item">
										<RouterLink v-if="item.to" :to="item.to" class="header__nav-link" @click="onClickHandle($event)">
											<LinkBorderLeft class="border border--left" aria-hidden="true" />
											<LinkBorderCenter class="border border--center" aria-hidden="true" />
											<span class="text">{{ item.title }}</span>
										</RouterLink>
										<a
											v-else
											:href="item.url"
											:target="item.target"
											:class="item.type === 'scrollToSection' ? 'anchor-link' : ''"
											@click="onClickHandle($event)"
											class="header__nav-link"
										>
											<LinkBorderLeft class="border border--left" aria-hidden="true" />
											<LinkBorderCenter class="border border--center" aria-hidden="true" />
											<span class="text">{{ item.title }}</span>
										</a>
									</li>
								</ul>
							</nav>

							<div class="header__contact-us-mail">
								For inquiries, reach out to us at <br />
								<a href="mailto:hello@saguni.studio">hello@saguni.studio</a>
							</div>
						</div>
					</div>

					<button ref="menuButton" class="header__menu-button" @click="mobileMenuToogle" aria-label="Menu Toggler">
						<IconMenu class="icon" />
					</button>
				</div>
			</div>

			<div ref="mobileMenuBackdrop" class="header__mobile-menu-backdrop"></div>
		</div>
	</header>
</template>

<script>
import { RouterLink } from 'vue-router';
import IconMenu from '@/components/icons/IconMenu.vue';
import LinkBorderLeft from '@/components/icons/LinkBorderLeft.vue';
import LinkBorderCenter from '@/components/icons/LinkBorderCenter.vue';
import menus from '@/data/menus.json';
import { gsap } from 'gsap';
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app.js';
import { enableScroll } from '@/helpers/page-scroll.js';

export default {
	name: 'AppHeader',
	components: { RouterLink, IconMenu, LinkBorderLeft, LinkBorderCenter },
	data: () => ({
		menu: menus.header_menu,
	}),
	computed: {
		...mapState(useAppStore, ['isHeaderAnimationPlayed']),
	},
	mounted() {
		if (!this.isHeaderAnimationPlayed && window.location.pathname === '/') {
			window.addEventListener('3d-app:complete-first-animation', this.onCompleteFirstAnimation);
			this.createTimeline();
		} else {
			this.$nextTick(() => {
				enableScroll();
			});
		}

		window.addEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions(useAppStore, ['setHeaderAnimationPlayed']),
		createTimeline() {
			const { headerWrap, logo, navInner, menuButton } = this.$refs;
			this.tl = gsap.timeline({ delay: 1, onComplete: this.onTimelineComplete });

			gsap.set(headerWrap, { y: '-100%', opacity: 0 });
			gsap.set(logo, { y: '-100%', opacity: 0 });
			gsap.set(navInner, { y: '-100%', opacity: 0 });
			gsap.set(menuButton, { y: '-100%', opacity: 0 });

			this.tl
				.to(headerWrap, { y: 0, opacity: 1 }, 0)
				.to(logo, { y: 0, opacity: 1, delay: 0.3 }, 0)
				.to(navInner, { y: 0, opacity: 1, delay: 0.6 }, 0)
				.to(menuButton, { y: 0, opacity: 1, delay: 0.6 }, 0);
			this.tl.pause();
		},
		onCompleteFirstAnimation() {
			if (this.tl) {
				this.tl.resume();
				this.setHeaderAnimationPlayed(true);
			}
		},
		onTimelineComplete() {
			enableScroll();
		},
		mobileMenuToogle() {
			const { nav, mobileMenuBackdrop } = this.$refs;
			mobileMenuBackdrop.classList.toggle('active');
			nav.classList.toggle('active');
		},
		hideMenu() {
			const { nav, mobileMenuBackdrop } = this.$refs;
			mobileMenuBackdrop.classList.remove('active');
			nav.classList.remove('active');
		},
		onResize() {
			if (window.matchMedia('(min-width: 768px)').matches) {
				this.hideMenu();
			}
		},
		onClickHandle(e) {
			e.preventDefault();
			let target = e.currentTarget;

			if (document.documentElement.classList.contains('mobile-scroll')) {
				document.getElementById('app').scrollTop = 0;
			} else {
				window.scrollTo({ top: 0, behavior: 'instant' });
			}

			if (target.classList.contains('anchor-link')) {
				let targetId = target.getAttribute('href');
				let section = document.querySelector(targetId);

				if (!section) return;

				this.hideMenu();
				const scrollPosition = section.offsetTop;
				if (document.documentElement.classList.contains('mobile-scroll')) {
					document.getElementById('app').scrollTo({ top: scrollPosition, behavior: 'smooth' });
				} else {
					window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
				}
			} else {
				this.hideMenu();
			}
		},
	},
	unmounted() {
		window.removeEventListener('resize', this.onResize);
	},
};
</script>
